5<template>
	<div class="backcolor-f">
		<div style="position: absolute;z-index: 9;top:10px;right: 10px;">
			<el-button class="cursor" size="mini" style="margin-right: 10px;" @click="distribution">批量分配</el-button> 
			<el-button type="primary" class="cursor" @click="showimport=true" size="mini"
			 >
			导入客户</el-button>
		</div>
		<el-tabs v-model="activeName" type="card" >
			<el-tab-pane label="待分配客户" name="1">
				<div style="width: 100%;padding: 0px 20px;margin: 5px 0 ;">
					<el-table :data="CustomerList" v-loading="loading" tooltip-effect="dark" border stripe
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column label="客户全名" prop="UserName" width="140" height="40">
						</el-table-column>
						<el-table-column label="标签" prop="Label" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="就读学校" prop="School" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="年级" prop="Grade" width="100" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column label="最近动态时间" prop="RecentNewsTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.RecentNewsTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="最后跟进人" prop="FollowUserName" width="100px"> </el-table-column>
						<el-table-column label="创建客户时间" prop="AddTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.AddTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-dropdown @command="handleCommand">
									<span class="el-dropdown-link cursor">
										操作<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="{val:scope.row}">分配客户</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>
					</el-table>
				</div>

			</el-tab-pane>
		</el-tabs>
		<el-pagination background layout="total,prev, pager, next,sizes,jumper" highlight-current-row :total="total"
			:page-size="listQuery.pageSize" :page-sizes="[15,20,50,100,200,500]" @current-change="pageIndexChange"
			@size-change="pageSizeChange" style="text-align: center;" />
		<div class="margin-top padding-lr-lg">


		</div>
		<importCustom
		:Visible="showimport"
		:title="'客户导入'" 
		:action="'/antis-service/api/Customer/Import?type=4'"
		@closepop="importclose"
	  ></importCustom>
	</div>
</template>

<script>
	import importCustom from '../myClient/components/importCustom.vue'
	import AsList from '@/components/as-list'
	export default {
		name: 'management',
		components: {
			AsList,
			importCustom
		},
		data() {
			return {	showimport:false,
				loading: true,
				distributionCustomerids:'',
				total: 0,
				activeName: '1',
				CustomerList: [],
				listQuery: {
					CustomerType: 4,
					pageIndex: 1,
					pageSize: 15,
					Sort: [{
						Field: "id",
						Type: 1
					}]
				},
			}
		},
		created() {
			this.getCustomerList()
		},
		methods: {
			importclose(){
				// this.listQuery= JSON.parse(JSON.stringify(this.listQuery));
				this.getCustomerList();
				this.showimport= false;
			},
			goNav(url) {
				this.$router.push(url)
			},
			pageSizeChange(val) {
				this.listQuery.PageIndex = 1;
				this.listQuery.pageSize = val;
				this.getCustomerList();
			},
			pageIndexChange(val) {
				this.listQuery.PageIndex = val;
				this.getCustomerList();
			},
			getCustomerList() {
				this.API.selectCustomerList(this.listQuery).then(res => {
					this.loading = false
					this.CustomerList = res.data.rows
					this.total = res.data.total
				})
			},
			
			handleSelectionChange(val) {
				let ids=[]
				val.forEach(item=>{
					ids.push(item.id)
				})
				this.distributionCustomerids=ids.join(',')
				console.log(this.distributionCustomerids);
			},
			handleCommand(command) {
				this.$Dialog.ChooseSchool({Customerids:command.val.id}).then(res=>{
					this.getCustomerList()
				})
			},
			//批量分配
			distribution(){
				if(this.distributionCustomerids.length!=0){
					this.$Dialog.ChooseSchool({Customerids:this.distributionCustomerids}).then(res=>{
						this.getCustomerList()
					})
					console.log(this.distributionCustomerids)
				}else{
					this.$message("请先选择需要被分配的客户")
				}
			}
		}
	}
</script>

<style scoped>
	>>>.el-table {
		box-shadow: 0 0 8px #cdcdcd;
	}

	>>>.el-table th {
		background-color: #2FA4E7;
		color: #fff;
	}

	>>>.el-tabs__item:hover {
		color: #2FA4E7;
	}

	.myClient-right-5 {
		color: #2FA4E7;
		font-weight: bold;
		font-size: 16px;
		margin-right: 10px;
	}

	>>>.is-active {
		height: 43px;
		font-size: 17px;
		font-weight: bold !important;
	}
</style>
